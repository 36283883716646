<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Lista de Fabricantes</h4>

          <div class="page-title-right">
            <b-button
              variant="success"
              @click.prevent="$router.push({ name: 'fabricante' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="fGridData.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div v-for="f in fGridData" :key="f.id" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <div class="mb-4">
              <img
                v-if="f.image != null"
                class="rounded-circle avatar-sm"
                :src="`${f.image[0].image300}`"
                alt
              />
              <img
                v-else
                class="rounded-circle avatar-sm"
                src="@/assets/images/notpro.jpg"
                alt
              />
            </div>
            <h5 class="font-size-15 text-dark">
              {{ f.name }}
            </h5>
            <p class="text-muted">{{ f.created_at | moment('DD/MM/YYYY') }}</p>
            <span
              class="badge badge-pill badge-soft-success font-size-12"
              :class="{
                'badge-soft-success': `${f.status}` == 0,
                'badge-soft-danger': `${f.status}` == 1,
              }"
            >
              {{ f.status == 0 ? 'Ativo' : 'Bloqueado' }}
            </span>
          </div>
          <div
            class="card-footer bg-transparent border-top text-center font-size-20"
          >
            <button
              @click.prevent="
                $router.push({ name: 'fabricante-edit', params: { id: f.id } })
              "
              class="btn btn-success"
              v-b-tooltip.hover.top
              title="Editar"
              href="javascript: void(0);"
            >
              <i class="fas fa-pencil-alt text-white"></i>
            </button>
            <button
              @click.prevent="removerItem(f)"
              class="btn btn-danger ml-2"
              v-b-tooltip.hover.top
              title="Remover"
            >
              <i class="fas fa-trash-alt text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      fGridData: [],
      filtro: {
        limit: 20,
        page: 1,
      },
      loading: true,
      error: false,
    }
  },
  methods: {
    ...mapActions('manufacturer', [
      'ActionManufacturerAll',
      'ActionManufacturerUpdate',
    ]),
    async listManufacturer() {
      try {
        await this.ActionManufacturerAll(this.filtro).then((res) => {
          this.fGridData = res.data.data.list
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.fGridData = []
      }
    },
    infiniteHandler($state) {
      try {
        this.ActionManufacturerAll(this.filtro).then(({ data }) => {
          if (data.data.list.length) {
            this.filtro.page += 1
            this.fGridData.push(...data.data.list)
            $state.loaded()
          } else {
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        $state.complete()
      }
    },
    removerItem(data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(data)
        }
      })
    },
    async removerItemUp(data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionManufacturerUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.fGridData.splice(this.fGridData.indexOf(t), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
